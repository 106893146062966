@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

html {
    overflow: hidden;
}

@layer utilities {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}



::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    width: 3px;
    border: none;
    background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
    display: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #464646;
}

::-webkit-scrollbar-track {
    background-image: url("https://i.imgur.com/dLo0kkc.png");
    background-repeat: repeat-y;
    background-size: contain;
}


input[type="range"] {
    border-radius: 0%;
}

input[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    cursor: ew-resize;
    background: rgba(129, 129, 129, 0.336);
    box-shadow: -2000px 0 0 2000px #7c0bc7;
    border-radius: 0%;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: transparent;
    cursor: pointer;
    height: 0px;
    width: 0px;
}


input[type="range"]::-moz-range-thumb {
    width: 0;
    height: 0;
    border: none;
}




.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -10;
    background: radial-gradient(50% 50% at 50% 50%, #34d39921 0, rgba(255, 255, 255, 0) 100%);
    width: 200vw;
    height: 200vh;
    transform: translate(-50vw, -100vh);
}

/* Modal */
[data-reach-dialog-overlay] {
	z-index: 2000;
	background-color: transparent;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, .00);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}